import { render, staticRenderFns } from "./schemeLibrary.vue?vue&type=template&id=0f80013a&scoped=true&"
import script from "./schemeLibrary.vue?vue&type=script&lang=js&"
export * from "./schemeLibrary.vue?vue&type=script&lang=js&"
import style0 from "./schemeLibrary.vue?vue&type=style&index=0&id=0f80013a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f80013a",
  null
  
)

export default component.exports